.unit-service-v1 {
	background-color: var(--color-white);
	margin-top: 15px;
	border-radius: var(--border-radius);
	border: 1px solid var(--color-grey-dark);
	font-size: var(--font-size-md);

	.phone-link {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.title {
		font-weight: 700;
		font-size: var(--h5-font-size);
	}

	.image {
		img {
			width: 100%;
			object-fit: cover;
			border-radius: var(--border-radius);

		}
	}

	.info {
		padding: 10px;
	}

	.fa-sharp {
		padding-right: 5px;
	}

	a {
		color: var(--font-color-main);

		&:hover {
			text-decoration: underline;
		}
	}

	div.h5 {
		margin-top: 10px;
		font-size: .875rem;
	}
}