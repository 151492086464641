.faq-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.faq {
			background-image: url(RESOURCE/img/faq-hero.webp);
		}
	}

	.accordion-item {
		//border-color: #d5f1f1;
	}

	.accordion-button:not(.collapsed) {
		background-color: var(--alert-info-bg);
	}

	.accordion-button:focus {
		border-color: #e2f9f9;
		box-shadow: var(--alert-info-bg);
	}

	.title {
		font-size: 1.4rem;
		font-size: var(--h3-font-size);
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		text-indent: 5px;
	}

	.accordion-button {
		font-size: 20px;
		color: var(--font-color-main);
		font-weight: 600;
	}

	.accordion-body {
		color: var(--font-color-main);
	}

}