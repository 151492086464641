.parallax-v1 {
	position: relative;

	@media (min-width:768px) {

		background-image: url(RESOURCE/img/index_sonderangebote.webp);
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 600px;


	}

	@media (max-width:767px) {
		background-attachment: scroll;
		padding: 0 15px;
	}

	.parallax-box {
		padding: 20px;
		background-color: var(--color-primary);

		border-radius: var(--border-radius);

		@media (max-width:992px) {
			margin-top: 80px;
			margin-left: 10px;
			display: flex;
			vertical-align: middle;
			flex-direction: column;
			justify-content: space-around;
		}

		@media (min-width:991px) {

			margin-top: 100px;
			margin-left: 10px;
			display: flex;
			vertical-align: middle;
			flex-direction: column;
			justify-content: space-around;
		}

		@media (max-width:767px) {
			margin-left: 0;
		}

		.title {
			color: var(--color-white);
		}

		.text {
			color: var(--color-white);
		}
	}

}